<template>
  <div class="clients-filter-container">
    <div class="client-filter-row">
      <div class="client-filter-row__label">
        <strong>Клиент:</strong>
      </div>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>Телефон или имя</label>
          <v-text-field
              autocompete="off"
              @change="filtersUpdated"
              v-model="filters.client_f.query"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Банк. карта</label>
          <v-select
              @change="filtersUpdated"
              v-model="filters.client_f.defaultCard"
              item-text="name"
              item-value="value"
              :items="[{name:'Неважно', value: ''}, {name:'Привязана', value: '1'}, {name:'Не привязана', value: '0'}]"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Уборок >=</label>
          <v-row>
            <v-col class="py-0">
              <v-select @change="filtersUpdated" outlined v-model="filters.client_f.ordersCountSign"
                        :items="['>=', '=', '<=']"/>
            </v-col>
            <v-col class="py-0">
              <v-text-field @change="filtersUpdated" outlined v-model="filters.client_f.ordersCount"/>
            </v-col>
          </v-row>
        </div>
        <div class="client-filter-row__item">
          <label>Бонусов >=</label>
          <v-text-field @change="filtersUpdated" outlined v-model="filters.client_f.bonus"/>
        </div>
        <div class="client-filter-row__item">
          <label>Приложение</label>
          <v-select
              v-model="filters.client_f.withApp"
              @change="filtersUpdated"
              item-text="name"
              hide-details
              item-value="value"
              :items="[{name: 'Неважно', value: 0},{name: 'Есть', value: 1},{name: 'Нет', value: 2},]"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Пол</label>
          <v-select
              v-model="filters.client_f.gender"
              @change="filtersUpdated"
              multiple
              hide-details
              item-text="name"
              item-value="value"
              :items="[{name: 'Мужской', value: 1},{name: 'Женский', value: 2},{name: 'Не определено', value: 0}]"
              outlined
          ></v-select>
        </div>
      </div>
    </div>
    <div class="client-filter-row">
      <div class="client-filter-row__label">
        <strong>Адрес:</strong>
      </div>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>Поиск по улице</label>
          <v-text-field
              autocompete="re-new-password"
              @change="filtersUpdated"
              v-model="filters.address.street"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Кол-во комнат</label>
          <v-select
              @change="filtersUpdated"
              v-model="filters.address.flattype"
              multiple
              :items="['1','2','3','4','5']"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Регион</label>
          <v-select
              v-model="filters.address.region"
              @change="filtersUpdated"
              multiple
              :items="[{n_id:0, title:'Любой'}].concat($store.state.user.regions)"
              item-text="title"
              item-value="n_id"
              outlined/>
        </div>
      </div>
    </div>
    <div class="client-filter-row">
      <div class="client-filter-row__label">
        <strong>Первый заказ:</strong>
      </div>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>Дата уборки</label>
          <v-menu
              ref="menu"
              transition="scale-transition"
              left
              :close-on-content-click="false"
              bottom
              nudge-bottom="35"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="$root.outputRange(filters.order.first_order)"
                  readonly
                  v-bind="attrs"
                  @change="filtersUpdated"
                  style="width: 100%"
                  v-on="on"
              />
              <v-btn
                  icon
                  @click.stop="filters.order.first_order =[]"
                  color="grey"
                  :style="filters.order.first_order && filters.order.first_order.length ? '' : 'display: none'"
                  style="position: absolute;right: 5px;top: 28px;z-index: 100;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-date-picker
                v-model="filters.order.first_order"
                no-title
                scrollable
                range
                first-day-of-week="1"
                locale="ru"
            />
          </v-menu>
        </div>
      </div>
    </div>
    <div class="client-filter-row">
      <div class="client-filter-row__label">
        <strong>Заказ:</strong>
      </div>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>Дата уборки</label>
          <v-menu
              ref="menu"
              transition="scale-transition"
              left
              :close-on-content-click="false"
              bottom
              nudge-bottom="35"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="$root.outputRange(filters.order.dateclean)"
                  readonly
                  @change="filtersUpdated"
                  v-bind="attrs"
                  style="width: 100%"
                  v-on="on"
              />
              <v-btn
                  icon
                  @click.stop="filters.order.dateclean =[]"
                  color="grey"
                  :style="filters.order.dateclean && filters.order.dateclean.length ? '' : 'display: none'"
                  style="position: absolute;right: 5px;top: 28px;z-index: 100;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-date-picker
                v-model="filters.order.dateclean"
                no-title
                scrollable
                range
                first-day-of-week="1"
                locale="ru"
            />
          </v-menu>
        </div>
        <div class="client-filter-row__item">
          <label>Дата создания</label>
          <v-menu
              ref="menu"
              transition="scale-transition"
              left
              :close-on-content-click="false"
              bottom
              nudge-bottom="35"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="$root.outputRange(filters.order.datecreate)"
                  readonly
                  @change="filtersUpdated"
                  v-bind="attrs"
                  style="width: 100%"
                  v-on="on"
              />
              <v-btn
                  icon
                  @click.stop="filters.order.datecreate =[]"
                  color="grey"
                  :style="filters.order.datecreate && filters.order.datecreate.length ? '' : 'display: none'"
                  style="position: absolute;right: 5px;top: 28px;z-index: 100;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <v-date-picker
                v-model="filters.order.datecreate"
                no-title
                scrollable
                range
                first-day-of-week="1"
                locale="ru"
            />
          </v-menu>
        </div>
        <div class="client-filter-row__item">
          <label>Тип уборки</label>
          <v-select
              v-model="filters.order.type"
              @change="filtersUpdated"
              item-text="name"
              multiple
              item-value="value"
              :items="orderTypes"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Промо</label>
          <v-text-field @change="filtersUpdated" v-model="filters.order.promo" outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Доп услуги</label>
          <v-select
              v-model="filters.order.services"
              @change="filtersUpdated"
              item-text="name"
              multiple
              item-value="value"
              :items="[{name: 'Мытье окон', value: 'windowNum'}]"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Есть промо</label>
          <v-select
              v-model="filters.order.withPromo"
              @change="filtersUpdated"
              item-text="name"
              item-value="value"
              :items="[{name: 'Неважно', value: 0},{name: 'Есть', value: 1},{name: 'Нет', value: 2},]"
              outlined/>
        </div>
      </div>
    </div>
    <div class="client-filter-row">
      <div class="client-filter-row__label"/>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>Есть скидка</label>
          <v-select
              v-model="filters.order.withDiscount"
              @change="filtersUpdated"
              item-text="name"
              item-value="value"
              :items="[{name: 'Неважно', value: 0},{name: 'Есть', value: 1},{name: 'Нет', value: 2},]"
              outlined/>
        </div>
        <div class="client-filter-row__item">
          <label>Стоимость уборки</label>
          <v-row>
            <v-col class="pa-0 pr-1">
              <v-text-field @change="filtersUpdated" outlined placeholder="От" v-model="filters.order.total_from"/>
            </v-col>
            <v-col class="pa-0">
              <v-text-field @change="filtersUpdated" outlined placeholder="До" v-model="filters.order.total_to"/>
            </v-col>
          </v-row>
        </div>
        <div class="client-filter-row__item">
          <label>Оценка</label>
          <v-select
              v-model="filters.order.feedback"
              @change="filtersUpdated"
              multiple
              :items="feedbackFilterItems"
              outlined
              item-text="text"
              item-value="value"
          />
        </div>
        <div class="client-filter-row__item">
          <label>Способ оформления</label>
          <v-select
              v-model="filters.order.createdIn"
              @change="filtersUpdated"
              multiple
              :items="$store.state.toSelectItems({'4_':'Android App','5_':'IOS App', '6_':'Сайт не Safari','7_':'Сайт Safari', '9_':'По подписке',  '8_':'CRM',  '1_':'Старый сайт',  '0_':'Старая црм'})"
              outlined
          />
        </div>
        <div class="client-filter-row__item">
          <label>Удаленные</label>
          <v-select
              v-model="filters.order.deleted"
              @change="filtersUpdated"
              multiple
              :items="$store.state.toSelectItems({'sent_to':'Отправлены в другой город','client':'Отменен клиентом', 'manager':'Отменен менеджером'})"
              outlined
          />
        </div>
      </div>
    </div>
    <div class="client-filter-row">
      <div class="client-filter-row__label">
        <strong>UTM:</strong>
      </div>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>utm_source</label>
          <v-text-field @change="filtersUpdated" outlined placeholder="yandex" v-model="filters.order.utm_source"/>
        </div>
        <div class="client-filter-row__item">
          <label>utm_medium</label>
          <v-text-field @change="filtersUpdated" outlined placeholder="cpc" v-model="filters.order.utm_medium"/>
        </div>
        <div class="client-filter-row__item">
          <label>utm_campaign</label>
          <v-text-field @change="filtersUpdated" outlined placeholder="ПОИСК | клининг | Зеленоград"
                        v-model="filters.order.utm_campaign"/>
        </div>
      </div>
    </div>
    <div class="client-filter-row">
      <div class="client-filter-row__label">
        <strong>Не было заказов:</strong>
      </div>
      <div class="client-filter-row-items">
        <div class="client-filter-row__item">
          <label>Дней</label>
          <v-text-field @change="filtersUpdated" outlined v-model="filters.order.no_orders"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientsFilter",
  props: ['filters'],
  emits: ['filtersUpdated'],
  data() {
    let orderTypes = [];
    for (let i in this.$store.state.cleanTypes) {
      orderTypes.push({name: this.$store.state.cleanTypes[i], value: i});
    }
    return {
      orderTypes,
      feedbackFilterItems: [
        {value: '0', text: 'Без оценки'},
        {value: '1', text: '1'},
        {value: '2', text: '2'},
        {value: '3', text: '3'},
        {value: '4', text: '4'},
        {value: '5', text: '5'}
      ]
    }
  },
  methods: {
    filtersUpdated() {
      this.$emit('filtersUpdated');
    }
  }
}
</script>

<style scoped lang="scss">
.client-filter-row {
  margin-top: 16px;
  display: grid;
  align-items: flex-end;
  grid-gap: 10px;
  grid-template-columns:100px 1fr;

  &-items {
    display: grid;
    align-items: flex-end;
    grid-gap: 10px;
    grid-template-columns:repeat(auto-fit, minmax(100px, 200px));
  }
}

@media screen and (max-width: 1024px) {
  .client-filter-row {
    display: block;
  }
}
</style>