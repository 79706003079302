<template>
  <v-dialog data-app v-model="opened" :retain-focus="false" max-width="800px" @click:outside="close">
    <v-container style="padding: 0; min-height: auto">
      <v-icon class="btn-close-modal" @click="close()" size="36">mdi-close</v-icon>
      <v-card style="margin-bottom: 0">
        <v-card-title>Фильтры</v-card-title>
        <v-card-actions>
          <v-btn @click="setFilters" :loading="loading" style="background: #eee !important;">Применить</v-btn>
          <v-btn @click="setDefaultFilters" class="v-btn-danger">Сбросить</v-btn>
        </v-card-actions>
        <v-card-text>
          <div v-if="loading" class="clients-count-loading">Выполняется подсчет клиентов</div>
          <div v-else class="clients-count">Будет отправлено {{ clients_count }}
            {{ $root.numberWord(clients_count, ['клиенту', 'клиентам', 'клиентам']) }}
          </div>
          <v-select
              v-if="+$store.state.user.city.id===1"
              v-model="cities"
              :items="[{text: 'Выбрать все', value: 'all'}, ...$store.state.toSelectItems($store.state.user.cities, 'city', 'id')]"
              item-value="value"
              item-text="text"
              @change="saveLocal($event)"
              placeholder="Выберите один или несколько"
              outlined multiple/>
          <ClientsFilter :filters="filters"/>
        </v-card-text>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import ClientsFilter from "@/components/ClientsFilter";
import globalMixins from "@/mixins/globalMixins";

export default {
  name: "SendModal",
  components: {ClientsFilter},
  props: [],
  mixins: [globalMixins],
  emits: ['on-set-filters'],
  data() {
    let defaultFilters = {
      client_f: {defaultCard: '', ordersCount: '', gender: [], ordersCountSign: '>='},
      address: {},
      order: {dateclean: [], datecreate: [], first_order: [], withPromo: 0, withDiscount: 0, services: []},
      // layout: {showChart: false,},
    };
    let filters = this.clone(defaultFilters);
    return {
      defaultFilters,
      filters,
      cities: [],
      citiesPrev: [],
      opened: false,
      id: 0,
      loading: false,
    }
  },
  methods: {
    open(id, filters, cities, clients_count) {
      this.opened = true;
      this.id = id;
      this.clients_count = clients_count;
      this.cities = this.clone(cities);
      this.citiesPrev = this.cities;
      this.filters = this.clone((filters && Object.keys(filters).length) ? filters : this.defaultFilters);
    },
    close() {
      this.opened = false;
    },
    prepareParams() {
      let params = this.clone(this.filters);
      params.page = this.page;
      params.chart = this.showChart ? 1 : 0;
      params.revenueTable = this.showRevenueCost ? 1 : 0;
      params.revenueTableByOrders = this.revenueTableByOrders;
      let moreOrEq = ['ordersCount', 'bonus'];
      moreOrEq.forEach((field) => {
        if (+params.client_f[field]) params.client_f[field] = '>=' + (+params.client_f[field]);
        else params.client_f[field] = '';
      })
      return params;
    },
    setFilters() {
      this.loading = true;
      this.$store.state.server.request('mailingListItem/setRows/'+this.id, {
        filters: this.prepareParams(),
        cities: this.cities
      }, data => {
        this.clients_count = data.clients_count;
        this.loading = false;
        this.opened = false;
        this.$emit('on-set-filters');
      }, () => {
        this.loading = false;
      });
    },
    setDefaultFilters() {
      this.filters = this.clone(this.defaultFilters);
    },
    saveLocal() {
      if (this.cities.includes('all') && !this.citiesPrev.includes('all')) this.cities = ['all'].concat(this.$store.state.getObjectsField(this.$store.state.user.allCities));
      else if (!this.cities.includes('all') && this.citiesPrev.includes('all')) this.cities = ['1'];
      this.citiesPrev = this.cities.slice();
    }
  }
}
</script>

<style scoped>

</style>